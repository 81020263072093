<template>
  <div style="padding-bottom: 150px">
    <van-checkbox-group v-model="result" ref="checkboxGroup">
      <van-cell-group>
        <van-cell
          v-for="(item, index) in formData"
          :key="item.fieldStr"
          :title="`${item.fieldStr}`"
          @click="toggle(index)"
        >
          {{item.valueStr + '  ' }} 
          <template #right-icon>
            <van-checkbox v-model="item.checked" :name="item.field" ref="checkboxes" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-checkbox-group>
      <div class="form-btn-view">
        <van-row class="btns">
          <van-col span="6">
            <van-button type="primary" size="large" round @click="ALLcheck">全/反选</van-button>
          </van-col>
          <van-col span="18">
            <van-button type="info" size="large" round @click="submit">确认已核查</van-button>
          </van-col>
        </van-row>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userId: '',
      resId: '',
      allCheck: false,
      result: [],
      formData: []
    }
  },
  methods: {
    ALLcheck () {
      if (this.formData.length !== this.result.length) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.checkboxGroup.toggleAll();
      }
      console.log(this.formData);
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    submit () {
      if (this.result.length < 1) {
        return this.$toast.fail('请先选择核查数据');
      }
      this.$dialog.confirm({
        message: '勾选数据确定已核查吗?',
        title: '提示',
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/user/batchCheck`),
          method: 'post',
          params: this.$http.adornParams({
            userId: this.userId,
            resId: this.resId,
            fieldNameList: this.result.join(',')
          })
        }).then(({data}) => {
          if (data.code == 0) {
            this.$toast.success('核查成功');
            setTimeout(() => {
              this.$router.go(-1)
            }, 300);
          } else {
            this.$message.fail(data.msg);
          }
        })
      })
    }
  },
  created () {
    this.userId = this.$route.query.userId
    this.resId = this.$route.query.resId
    this.formData = JSON.parse(this.$route.query.data)
  }
}
</script>

<style>

</style>